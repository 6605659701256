import React, { Component } from 'react';
import '../../App.css';
import styled from 'styled-components';
import { Header, MobileHeader } from '../generic/header';
import { WhiteH2, WhiteH4 } from '../generic/heading';

const LargeWhiteLabel = styled.label`
    font-size: 28px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const SmallWhiteLabel = styled.label`
    font-size: 24px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobLargeWhiteLabel = styled.label`
    font-size: 22px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

const MobSmallWhiteLabel = styled.label`
    font-size: 20px;
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'AmericanTypewriter';
    text-align: center;
`;

class Live extends Component {
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: 0,
          windowHeight: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
        let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

        this.setState({ windowWidth, windowHeight });
    }

    mainContent(styles) {
        if (styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion) {
            return (
                <div className="full-page">
                    <div className="partial-page main-content-padding">
                        <WhiteH2 content="Upcoming Public Gigs" />
                    </div>
                    <div className="partial-page live-content-padding">
                        <LargeWhiteLabel style={{margin: 0}}><b>O'Neill's, Leicester</b></LargeWhiteLabel>
                        <SmallWhiteLabel style={{margin: 0}}>07 Dec 2024 | Free entry | From 9pm</SmallWhiteLabel>
                    </div>
                    <hr size="1" width="50%" color="white" />
                    <div className="partial-page live-content-padding">
                        <LargeWhiteLabel style={{margin: 0}}><b>Big Difference, Leicester (with Nirvardy)</b></LargeWhiteLabel>
                        <SmallWhiteLabel style={{margin: 0}}>31 Jan 2025 | Free entry | From 9pm</SmallWhiteLabel>
                    </div>
                </div>
            );
        }

        return (
            <div className="full-page">
                <div className="full-page main-content-padding">
                    <WhiteH4 content="Upcoming Public Gigs" />
                </div>
                    <div className="partial-page live-content-padding">
                        <MobLargeWhiteLabel style={{margin: 0}}><b>O'Neill's, Leicester</b></MobLargeWhiteLabel>
                        <MobSmallWhiteLabel style={{margin: 0}}>07 Dec 2024 | Free entry | From 9pm</MobSmallWhiteLabel>
                    </div>
                    <hr size="1" width="50%" color="white" />
                    <div className="partial-page live-content-padding">
                        <MobLargeWhiteLabel style={{margin: 0}}><b>Big Difference, Leicester (with Nirvardy)</b></MobLargeWhiteLabel>
                        <MobSmallWhiteLabel style={{margin: 0}}>31 Jan 2025 | Free entry | From 9pm</MobSmallWhiteLabel>
                    </div>
            </div>
        );
    }

    render() {
        const { windowWidth } = this.state;

        const styles = {
            showFullVersion: windowWidth > 1680,
            showVersion1: windowWidth > 1543 && windowWidth <= 1680,
            showVersion2: windowWidth > 1400 && windowWidth <= 1543,
            showVersion3: windowWidth > 1200 && windowWidth <= 1400,
            showMidVersion: windowWidth > 900 && windowWidth <= 1200,
            showTabVersion: windowWidth > 480 && windowWidth <= 900
        };

        return (
            <div className="content">
                {styles.showFullVersion || styles.showVersion1 || styles.showVersion2 || styles.showVersion3 || styles.showMidVersion ? (
                    <Header content="Live" />
                ) : (
                    <MobileHeader content="Live" />
                )}
                {this.mainContent(styles)}
            </div>

        );
      }
}

export default Live;